import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import styles from './footer.module.scss';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        footerImage: file(relativePath: { eq: "logos/logoWhite.png" }) {
          childImageSharp {
            fluid(maxWidth: 271) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              originalName
            }
          }
        }
      }
    `}
    render={({ footerImage }) => {
      return (
        <footer className={styles.footer}>
          <div className={styles.footerContent}>
            <Link to="/" className={styles.logo}>
              <Img fluid={footerImage.childImageSharp.fluid} alt="Mindset Family Therapy" />
            </Link>
            <div className={styles.linksContainer}>
              <div className={styles.links}>
                <Link to="/">Home</Link>
                &nbsp; | &nbsp;
                <Link to="/about">About</Link>
                &nbsp; | &nbsp;
                <Link to="/team">Team</Link>
                &nbsp; | &nbsp;
                <Link to="/specialties">Specialties</Link>
                &nbsp; | &nbsp;
                <Link to="/books">Books</Link>
                &nbsp; | &nbsp;
                <Link to="/blog">Blog</Link>
                &nbsp; | &nbsp;
                <Link to="/contact">Contact</Link>
              </div>
              <div className={styles.legal}>
                <a
                  href="https://res.cloudinary.com/dewd4pral/image/upload/v1728438187/MFT_Notice_of_Privacy_Practices.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notice of Privacy Practices
                </a>
                &nbsp; | &nbsp;
                <a
                  href="https://res.cloudinary.com/dewd4pral/image/upload/v1728437132/MFT_Notice_of_No_Surprises_Act.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  No Surprises Act
                </a>
                &nbsp; | &nbsp;
                <a
                  href="https://www.cms.gov/medical-bill-rights"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Your Rights
                </a>
              </div>
            </div>
          </div>
        </footer>
      );
    }}
  />
);

export default Footer;
