import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import styles from './bookTeaser.module.scss';

const BookTeaser = ({ bookName, shouldLinkToRoute, shouldShowPreview, whiteBackground }) => {
  let altText;
  let bookImage;
  let description;
  let route;
  let amazonLink;
  let amazonLinkText;
  let previewLink = '';
  let releaseNote = '';

  switch (bookName) {
    case 'Let Go of Anxiety':
      altText = 'Let Go of Anxiety - Book by Annabella Hagen';
      amazonLink = 'https://www.amazon.com/Let-Go-Anxiety-Mountains-Resilience-ebook/dp/B08PKV8ST3';
      bookImage =
        'https://res.cloudinary.com/dewd4pral/image/upload/v1609454652/letGoOfAnxietyBookSmall.png';
      description =
        "Is anxiety sabotaging your passions, goals, and dreams? Then it's time to ACT!";
      previewLink =
        'https://read.amazon.com/kp/embed?asin=B08PKV8ST3&preview=newtab&linkCode=kpe&ref_=cm_sw_r_kb_dp_NoqaGbRW6HE2C';
      route = '/books/let-go-of-anxiety';
      break;
    case 'The Masterpiece Mindset':
      altText = 'The Masterpiece Mindset - Book by David and Annabella Hagen';
      amazonLink = 'https://www.amazon.com/dp/0997321040';
      bookImage =
        'https://res.cloudinary.com/dewd4pral/image/upload/v1673221091/masterpiece_mindset_new.png';
      description =
        'Bring out the best in yourself as a parent so you can empower your kids to be confident, kind, and resilient.';
      previewLink =
        'https://www.amazon.com/dp/0997321040?asin=0997321040&revisionId=&format=4&depth=1';
      route = '/books/the-masterpiece-mindset';
      break;
    case 'Imperfectly Good':
      altText = 'Imperfectly Good - Book by Annabella Hagen';
      amazonLink =
        'https://www.amazon.com/Imperfectly-Good-Navigating-Religious-Anxiety-ebook/dp/B0BW7V3749/';
      amazonLinkText = null;
      bookImage =
        'https://res.cloudinary.com/dewd4pral/image/upload/v1673219903/ImperfectlyGood.png';
      description =
        'A guide to help you find relief and happiness in spite of religious or moral OCD (scrupulosity OCD).';
      releaseNote = null;
      route = '/books/imperfectly-good';
      break;
    default:
      break;
  }

  return (
    <div className={styles.wrapper}>
      <div className={whiteBackground ? styles.whiteBackgroundContainer : styles.colorContainer}>
        {shouldLinkToRoute && (
          <div className={styles.description}>
            {description}{' '}
            <Link to={route} className={styles.learnMore}>
              Learn more about Annabella Hagen
              {`'`}s&nbsp;book.
            </Link>
          </div>
        )}

        {shouldShowPreview && previewLink && (
          <div className={styles.description}>
            Checkout a free preview of <i>{bookName}</i> from Amazon{' '}
            <a
              className={styles.learnMore}
              href={previewLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              available here
            </a>
            .
          </div>
        )}

        <div className={styles.bookImageWrapper}>
          <img src={bookImage} alt={altText} />
        </div>

        {amazonLink && (
          <a href={amazonLink} target="_blank" rel="noopener noreferrer">
            <div
              className={whiteBackground ? styles.darkButton : styles.lightButton}
              role="presentation"
            >
              {amazonLinkText && amazonLinkText}
              {!amazonLinkText && 'BUY NOW ON AMAZON'}
            </div>
          </a>
        )}

        {!amazonLink && (
          <div
            className={`${whiteBackground ? styles.darkButton : styles.lightButton} ${
              styles.buttonNoOp
            }`}
            role="presentation"
          >
            Available soon on Amazon
          </div>
        )}

        {releaseNote && (
          <div className={styles.releaseNoteContainer}>
            <div className={styles.note}>
              PAPERBACK AVAILABLE ON
              <br />
              APRIL 4, 2023
              <br />
              AMAZON
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookTeaser;

BookTeaser.defaultProps = {
  bookName: '',
  shouldLinkToRoute: true,
  shouldShowPreview: false,
  whiteBackground: false,
};

BookTeaser.propTypes = {
  bookName: PropTypes.string,
  shouldLinkToRoute: PropTypes.bool,
  shouldShowPreview: PropTypes.bool,
  whiteBackground: PropTypes.bool,
};
